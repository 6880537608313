@use '@angular/material' as mat;
// @use 'sass:map'; //REMOVE NOT USED

@import './variables';

@include mat.core();

// Theme Config
body {
  --primary-color: #ff6600;
  --primary-lighter-color: #ff9148;
  --primary-darker-color: #ff4900;
  --text-primary-color: #{$dark-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
  --primary-background: #ffffff;
  --primary-form-text-color: #253858;
  --primary-form-border-color: #00000033;
  --primary-shadow-color: #00000014;
  --primary-gray-color: #999999;
  --slide-toggle-disabled: #00000061;
  --module-border-color: #dcdcdc;
  --upload-border-color: #cccccc;
  --disabled-button-background-color: #e6e6e6;
  --upload-progress-default: #0000000d;
  --upload-title-color: #000000de;
  --upload-size-color: #0000008a;
  --upload-progress-done: #93dfae;
}

$mat-primary: (
  main: #ff6600,
  lighter: #ff9148,
  darker: #ff4900,
  200: #ff6600,
  // For slide toggle,
  contrast:
    (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);

$theme-primary: mat.m2-define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #cc5500;
  --accent-lighter-color: #b94700;
  --accent-darker-color: #b93b00;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$light-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (
  main: #cc5500,
  lighter: #b94700,
  darker: #b93b00,
  200: #cc5500,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $light-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-accent: mat.m2-define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #e23636;
  --warn-lighter-color: #f6c3c3;
  --warn-darker-color: #d62222;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
  main: #e23636,
  lighter: #f6c3c3,
  darker: #d62222,
  200: #e23636,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat.m2-define-palette($mat-warn, main, lighter, darker);

$theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
  )
);
$altTheme: mat.m2-define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
  )
);

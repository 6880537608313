.business {
  // &__form {
  //   .mat-form-field-appearance-outline .mdc-notched-outline {
  //     border-radius: 4px;
  //     opacity: 1;
  //     border: 1px solid var(--primary-form-border-color);
  //   }

  //   .mat-mdc-select-value,
  //   mat-select-placeholder {
  //     > span {
  //       color: var(--primary-form-text-color);
  //       font-size: 16px;
  //     }
  //   }

  //   input {
  //     &.mat-mdc-input-element {
  //       color: var(--primary-form-text-color) !important;
  //       opacity: 0.87;
  //       background-color: transparent;
  //       font-size: 16px;
  //     }

  //     &:focus {
  //       border: 2px solid #0658e5 !important;
  //       box-shadow: none;
  //     }
  //   }

  //   .mat-mdc-text-field-wrapper {
  //     margin: 0.25em 0;
  //     width: 398px;
  //     height: 66px;
  //   }

  //   p {
  //     margin-bottom: 2px;
  //   }

  //   .mat-mdc-select-arrow {
  //     color: var(--primary-gray-color);
  //   }
  // }
}
.mdc-text-field--invalid {
  border: 2px solid #f44336 !important;
}

.product-details {
  &__form {
    .mat-mdc-form-field-required-marker {
      display: none;
    }
    .mat-checkbox-checkmark-path {
      stroke: #fff !important;
    }

    .mat-hint {
      font-family: 'Work Sans', sans-serif;
      text-align: left;
      font-size: 12px;
      color: #0000008a;
      opacity: 1;
    }
    .mat-mdc-form-field-flex {
      align-items: center;
    }
    .mat-form-field-appearance-fill {
      width: 100%;
    }
    .mat-mdc-text-field-wrapper {
      min-height: 56px;
      border-radius: 4px;
      opacity: 1;
      border: 1px solid var(--primary-form-border-color);
    }
    .mat-mdc-input-element {
      color: var(--primary-form-text-color) !important;
    }
    .mat-mdc-form-field-infix {
      padding-left: 0.5em;
    }

    //Remove arrows from number type input
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
    //Remove arrows from number type input

    .mat-select-placeholder {
      color: var(--primary-form-text-color);
      opacity: 0.87;
      background-color: transparent;
      font-size: 16px;
    }

    .mat-mdc-form-field:not(.mat-form-field-disabled)
      .mat-mdc-floating-label.mdc-floating-label {
      color: var(--primary-form-text-color);
      opacity: 0.87;
      background-color: transparent;
      font-size: 16px;
    }
    .mdc-text-field--filled:not(.mdc-text-field--disabled):not(
        .mdc-text-field--focused
      ):hover
      .mdc-floating-label {
      color: #e6e6e6;
      opacity: 0.87;
      background-color: transparent;
      font-size: 16px;
    }

    textarea {
      color: var(--primary-form-text-color);
      opacity: 0.87;
      background-color: transparent;
      font-size: 16px;
    }
    .mat-form-field-underline {
      display: none;
    }

    .mat-focused .mat-form-field-fill {
      box-shadow: none;
    }

    .mat-mdc-select-value,
    mat-select-placeholder {
      > span {
        color: var(--primary-form-text-color);
        font-size: 16px;
      }
    }

    input {
      &.mat-input-element {
        color: var(--primary-form-text-color);
        opacity: 0.87;
        background-color: transparent;
        font-size: 16px;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .mat-mdc-select-arrow {
      color: var(--primary-gray-color);
    }

    .mat-mdc-form-field-subscript-wrapper {
      font-family: 'Work Sans', sans-serif;
      margin-top: 4px;
    }
  }
}

.mat-mdc-option {
  color: var(--primary-form-text-color) !important;

  .mat-pseudo-checkbox.mat-option-pseudo-checkbox {
    color: unset !important;
  }

  &.mat-mdc-option-active {
    color: var(--primary-color) !important;
  }
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--primary-color);
}

.mat-checkbox-label {
  color: var(--primary-form-text-color);
  opacity: 0.87;
  background-color: transparent;
  font-size: 16px;
}

.mat-checkbox-frame {
  border-color: #00000099;
}

.mat-mdc-select-panel {
  background-color: var(--primary-background) !important;
}

[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  box-shadow: none;
}

.prefix-icon-field-wrapper {
  mat-form-field + mat-form-field,
  input,
  .mat-form-field-label {
    letter-spacing: normal;
    line-height: normal;
  }

  input.prefix-icon-field::-webkit-outer-spin-button,
  input.prefix-icon-field::-webkit-inner-spin-button {
    display: none;
  }

  input.prefix-icon-field {
    -moz-appearance: textfield;
  }

  .input-prefix {
    left: 0;
    top: 2px;
    font-size: 14px;
    color: #7c889b;
    opacity: 1;
  }
}

.mat-optgroup-label {
  font-family: 'Work Sans', sans-serif;
  color: #253858;
}

//radio-label
.mdc-label {
  color: var(--primary-form-text-color);
  opacity: 0.87;
  background-color: transparent;
  font-size: 16px;
}

//radio circle
.mdc-radio__outer-circle {
  border-color: #00000099 !important;
}
.mat-mdc-checkbox .mdc-checkbox__background {
  border-color: #00000099; //Change color
}

.mat-mdc-checkbox-checked {
  .mdc-checkbox__background {
    border-color: var(--primary-color) !important;
    background-color: var(--primary-color) !important;
  }
}
// create new menu checkbox on select inhouse settings
.mat-pseudo-checkbox-full {
  border-color: unset !important;
}
.mat-mdc-button:not(:disabled) {
  // color: var(--primary-form-text-color) !important;
  border-radius: 34px;
}
.mat-mdc-select-value {
  color: var(--primary-form-text-color);
}

.mat-form-field-appearance-outline .mdc-notched-outline {
  border-radius: 4px;
  opacity: 1;
  border: 1px solid var(--primary-form-border-color);
}

.mat-mdc-select-value,
mat-select-placeholder {
  > span {
    color: var(--primary-form-text-color);
    font-size: 16px;
  }
}

input {
  &.mat-mdc-input-element {
    color: var(--primary-form-text-color) !important;
    opacity: 0.87;
    background-color: transparent;
    font-size: 16px;
  }

  &:focus {
    box-shadow: none;
  }
}

.mat-mdc-text-field-wrapper {
  margin: 0.25em 0;
  width: 398px;
  height: 66px;
}

p {
  margin-bottom: 2px;
}

.mat-mdc-select-arrow {
  color: var(--primary-gray-color) !important;
}

.mat-mdc-select-value-text {
  font-size: 16px;
  font-family: Work Sans;
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
}

.mat-form-field + .mat-form-field {
  margin-left: 8px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}
.mat-mdc-standard {
  background-color: blue;
}

button.mat-raised-button.mat-primary {
  border-radius: 34px;
  box-shadow: 8px 8px 24px #ff893b69;
  padding: 9px 24px;
  min-width: 118px;

  &:disabled {
    background-color: var(--disabled-button-background-color);
    color: var(--primary-gray-color);
    cursor: not-allowed;
  }
}

.page-toolbar.mat-toolbar {
  background: transparent;
  justify-content: flex-end;
  padding: 0;
  margin-bottom: 40px;
  height: fit-content;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: var(--primary-color) !important;
  opacity: 1;
}
.mat-mdc-tab-labels {
  display: inline-flex !important;
}
.mdc {
  &-tab__content {
    .mdc-tab__text-label {
      color: var(--text-primary-darker-color) !important;
      font-size: 16px;
      // letter-spacing: 0.01px;
    }

    .mat-tab-label-content {
      text-align: left;
      // letter-spacing: 0.01px;
      position: relative;
      left: -9px;
    }
    //mat-mdc-tab-label-container mat-tab-label-container
    .mat-tab-label {
      color: var(--text-primary-darker-color);
      height: 48px;
      justify-content: flex-start;
      font-weight: 500;
    }
    .mat-tab-header {
      border-bottom: 1px solid rgb(108 100 100 / 12%);
    }
  }

  &__tabs--spaced {
    .mat-tab-label {
      color: var(--text-primary-darker-color);
      padding: 0 24px;
      min-width: 96px;
    }

    .mat-tab-label-active .mat-tab-label-content {
      color: var(--primary-color);
      opacity: 1;
    }
  }
}

.mat {
  &__card {
    .mat-mdc-card {
      background: var(--primary-background);
      color: var(--text-primary-darker-color);
      margin-top: 13px;
      box-shadow: 0px 3px 12px var(--primary-shadow-color);
      border-radius: 8px;
      opacity: 1;
      padding: 40px 15px 35px 15px;
    }

    .mat-card-title {
      color: var(--text-primary-darker-color);
      font-size: 22px;
      font-weight: 500;
    }

    .mat-card:not([class*='mat-elevation-z']) {
      box-shadow: 0px 3px 12px var(--primary-shadow-color);
    }
  }
}

.module {
  &__toggle {
    span.mat-slide-toggle-bar {
      background-color: var(--slide-toggle-disabled);
    }

    span.mat-slide-toggle-thumb {
      background-color: var(--primary-background);
    }
  }
}

.flex-wrapper {
  orderadmin-frontend-custom-input {
    max-width: 37%;
  }
}

.time-picker {
  &__input {
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 0;
    color: var(--primary-form-text-color);
    text-align: center;

    &:focus {
      outline: 0;
      border: 0;
      border-color: none;
      outline-offset: 0;
      box-shadow: none;
    }
  }
}

mat-error {
  letter-spacing: normal !important;
  line-height: normal !important;
  font-size: 16px !important;
  margin-left: 0 !important ;
}

.mat-autocomplete-panel {
  background-color: var(--primary-background);
}

.mat-dialog-container {
  background: #fff;
  color: #253858;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: unset !important;
}

.mdc-switch__track::after {
  background-color: var(--primary-color) !important;
}

.mat-expansion-panel {
  background-color: var(--primary-background) !important;
  color: var(--primary-form-text-color) !important;
}

[type='text'],
input:where(:not([type])),
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
[multiple],
textarea,
select {
  border: #fff;
}

.mat-mdc-menu-panel {
  background-color: white !important;
  & .mat-mdc-menu-item {
    color: var(--primary-form-text-color);
    &:not([disabled]):hover {
      background-color: var(--disabled-button-background-color);
    }
  }
}

.mat-input {
  color: var(--primary-form-text-color);
}
option {
  color: var(--primary-form-text-color);
}

.mat-mdc-raised-button:not(:disabled) {
  border-radius: 34px;
}
.mat-mdc-button-disabled {
  background-color: var(--disabled-button-background-color) !important;
  color: var(--primary-gray-color) !important;
  border-radius: 34px !important;
}

.mat-mdc-dialog-surface {
  background-color: var(--primary-background) !important;
  //
}

.mat-mdc-dialog-container .mat-mdc-dialog-title {
  color: var(--text-primary-darker-color) !important;
}
.mat-mdc-dialog-container-with-actions .mat-mdc-dialog-content {
  color: var(--text-primary-darker-color) !important;
}

mat-card-actions.mat-mdc-card-actions {
  display: flex;
  width: 100%;
  justify-content: flex-end !important;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background-color: var(--primary-gray-color) !important;
  :after {
    background-color: var(--primary-lighter-color);
  }
}
.mat-mdc-standard-chip.mdc-evolution-chip--selected:not(
    .mdc-evolution-chip--disabled
  ) {
  background-color: var(--primary-color) !important;
}
.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0px;
}

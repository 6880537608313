.table {
  &__wrapper {
    position: relative;

    table {
      width: 100%;
      background: var(--primary-background);
      position: relative;

      th,
      td {
        color: var(--text-primary-darker-color);
      }

      thead {
        position: sticky;
        top: 0;
        left: 0;
        background: var(--primary-background);
      }

      tbody,
      tr {
        background: transparent;
      }

      tr.custom-row {
        text-align: left;
      }

      tbody tr {
        height: 70px;

        &:nth-child(odd) {
          background-color: #0000000f;
        }
      }

      .mat-column-edit {
        color: var(--primary-color);
        font-size: 14px;
      }
    }

    .paginator {
      position: sticky;
      left: 0;
      bottom: 0;
      width: 100%;
      background: var(--primary-background);

      .mat-mdc-icon-button.mat-mdc-button-base.mat-mdc-button-disabled {
        color: var(--text-primary-darker-color);
        opacity: 0.3;
      }

      .mat-mdc-paginator-page-size,
      .mat-mdc-select-arrow,
      .mat-mdc-select-value,
      .mat-mdc-paginator-range-actions,
      .mat-mdc-paginator-icon {
        color: var(--text-primary-darker-color) !important;
        fill: var(--text-primary-darker-color);
      }

      .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
        fill: var(--text-primary-darker-color);
      }
    }
  }

  &__hidden {
    visibility: hidden;
  }
}

.mat-selected {
  .mat-option-text {
    color: var(--primary-color);
  }
}

.mat-select-panel.mat-primary {
  background: var(--primary-background);
}

.mat-option-text {
  color: var(--text-primary-darker-color);
}

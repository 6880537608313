@use '../../../../../node_modules/@angular/material' as mat;
@include mat.core();
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

$fa-font-path: '../webfonts';

// Fonts
@import 'node_modules/@awesome.me/kit-38b3aba841/icons/scss/fontawesome.scss';
@import 'node_modules/@awesome.me/kit-38b3aba841/icons/scss/solid.scss';
@import 'node_modules/@awesome.me/kit-38b3aba841/icons/scss/regular.scss';
@import 'node_modules/@awesome.me/kit-38b3aba841/icons/scss/duotone.scss';
@import 'node_modules/@awesome.me/kit-38b3aba841/icons/scss/custom-icons.scss';

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500&display=swap');

@import './typography';
@import './variables';
@import './modes';
@import './config';

// Theme Init
@include mat.all-component-themes($theme);

//TODO REMOVE OR CHANGE ALTERNATING
//https://github.com/angular/components/blob/main/guides/duplicate-theming-styles.md
// .theme-alternate {
//   @include mat.all-component-themes($altTheme);
// }

@import './form';
@import './overrides';
@import './snackbar';
@import './custom-input';

mat-snack-bar-container {
  &.success-snackbar {
    .mat-mdc-snackbar-surface {
      color: black !important;
      background-color: var(--upload-progress-done) !important;
    }
  }
  &.error-snackbar {
    .mat-mdc-snackbar-surface {
      background: var(--warn-color);
      color: var(--primary-background);
    }
  }
}
